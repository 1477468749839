import React, { useEffect } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import IndexPageComponent from "../../components/IndexPage"

function IndexPage({ location, data }) {
  useEffect(() => {
    if (typeof window.twttr !== "undefined") {
      window.twttr.widgets.load()
    }
  }, [])
  return (
    <Layout
      links={[
        "Perspektiven",
        "Buch: Gemeinsam sind wir klüger",
        "Buch: Value as a Service",
        "Nachrichten",
        "Fotogalerie",
        "Über Rob",
      ]}
      location={location}
    >
      <SEO
        title="Rob Bernshteyn | Verfasser von „Gemeinsam sind wir klüger“ und CEO von Coupa "
        description="Der CEO von Coupa, Autor und Unternehmensberater hat dieses Buch veröffentlicht: Gemeinsam sind wir klüger: Wie Gemeinschaften die nächste Revolution in der Geschäftswelt gestalten. Noch heute kaufen"
        keywords="Rob Bernshteyn, Buch „Gemeinsam sind wir klüger“, Gemeinsam sind wir klüger: Wie Gemeinschaften die nächste Revolution in der Geschäftswelt gestalten"
        url="https://robideas.com/"
        image="https://www.coupa.com/v2/assets/img/Rob_top_mobile.png"
      />
      <IndexPageComponent {...data} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
  heroBG: PropTypes.object,
  childImageSharp: PropTypes.object,
  fluid: PropTypes.object,
}

export default IndexPage

export const pageQuery = graphql`
  query GermanPageQuery {
    allMarkdownRemark(
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: {
          markdownFile: { ne: "article-cards" }
          featured: { eq: true }
        }
      }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            date(formatString: "MMMM-YYYY-DD")
            rawDate: date
            featured
            title
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    heroBG: file(relativePath: { eq: "hero-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    robBG: file(relativePath: { eq: "Rob_top_crop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    robMobile: file(relativePath: { eq: "Rob_top_mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    robQuote1: file(relativePath: { eq: "Rob_quote_1.png" }) {
      ...fluidImage
    }
    robQuote2: file(relativePath: { eq: "robquote2.png" }) {
      ...fluidImage
    }
    cta1: file(relativePath: { eq: "de-smarter-together.png" }) {
      ...fluidImage
    }
    cta2: file(relativePath: { eq: "de-value-as-a-service.png" }) {
      ...fluidImage
    }
    textYaml(yamlPage: { eq: "german" }) {
      heroTitle
      heroBody
      quoteText
      quoteAuthor
      ctaTitle
      ctaSubTitle
      ctaCopy
      ctaButton
      cardText {
        cardQuote
        cardAuthorTitle
      }
      ctaTitle1
      ctaSubTitle1
      ctaCopy1
      ctaButton1
      cardText2 {
        cardQuote
        cardAuthorTitle
      }
      quoteText1
      quoteAuthor1
      logoGridHeading
      tweetTitle
      tweetSubTitle
      tweetCopy
      tweetSubHeading
      tweetButton
      tweetButton1
      tweetPlaceholder
      logoGrid {
        image {
          ...fluidImage
        }
        heading
        alt
        href
        button
        largeImage
      }
    }
  }
`
